
import EditoTable from 'Organisms/edito-table';
import { commonProps } from '../../utils/prismic';

export default {
  components: {
    EditoTable,
  },
  props: commonProps,
};
